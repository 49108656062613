import { mapState } from 'vuex'

export default {
  data () {
    return {
      blokSlug: null
    }
  },
  computed: {
    ...mapState('storyblok', {
      blok (state) {
        return this.blokSlug && state.stories[this.blokSlug] && state.stories[this.blokSlug].story
      }
    })
  },
  async serverPrefetch () {
    await this.fetchBlok()
  },
  async mounted () {
    if (!this.blok) {
      await this.fetchBlok()
    }
  },
  methods: {
    async fetchBlok () {
      if (this.blokSlug) {
        return this.$store.dispatch(`storyblok/loadStory`, {
          fullSlug: this.blokSlug
        })
      }
    }
  }
}
