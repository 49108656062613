<template>
  <transition name="zoom">
    <div class="modal" v-if="isVisible" ref="modal" @click.self="close">
      <div class="modal-wrapper">
        <div class="modal-top mx-auto text-right max-w-xl pointer-events-auto px-md pt-sm lg:pt-0 lg:relative fixed right-0 left-0">
          <i class="ss-gizmo ss-delete lg:absolute modal-close text-black cursor-pointer leading-none inline-block border-b-2 hover:text-primary2 slow-color-change hover:border-primary2 lg:icon-xl3 right-0 text-center"
            @click="close" data-testid="closeModalButton" slot="close" />
        </div>
        <div class="modal-center w-full">
          <div class="modal-container-wrapper relative">
            <div class="modal-container" ref="modal-content" :style="style">
              <header class="modal-header">
                <slot name="header" />
              </header>
              <div class="modal-content py-sm" v-if="$slots.content">
                <slot name="content" />
              </div>
              <slot />
            </div>
            <footer class="modal-footer text-center">
              <slot name="footer" />
            </footer>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import {
  mapMutations
} from 'vuex'
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress'
export default {
  name: 'Modal',
  data() {
    return {
      isVisible: false,
      modalCloseActive: true
    }
  },
  methods: {
    onHide(name, state, params) {
      return name === this.name ? this.toggle(false) : false
    },
    onShow(name, state, params) {
      return name === this.name ? this.toggle(true) : false
    },
    onToggle(name, state, params) {
      if (name === this.name) {
        state = typeof state === 'undefined' ? !this.isVisible : state
        this.toggle(state)
      }
    },
    onEscapePress() {
      this.close()
    },
    onModalCloseState (name, state, params) {
      if (name === this.name) {
        // debugger
        this.modalCloseActive = typeof state === 'undefined' ? true : state
      }
    },
    ...mapMutations('ui', [
      'setOverlay'
    ]),
    toggle(state) {
      this.isVisible = state
      state ? this.setOverlay(state) : setTimeout(() => this.setOverlay(state), this.delay)
    },
    close() {
      if (this.modalCloseActive) {
        this.toggle(false)
      }
    }
  },
  beforeMount() {
    this.$bus.$on('modal-toggle', this.onToggle)
    this.$bus.$on('modal-close-state', this.onModalCloseState)
    this.$bus.$on('modal-show', this.onShow)
    this.$bus.$on('modal-hide', this.onHide)
  },
  beforeDestroy() {
    this.$bus.$off('modal-toggle', this.onToggle)
    this.$bus.$off('modal-show', this.onShow)
    this.$bus.$off('modal-hide', this.onHide)
  },
  mixins: [onEscapePress],
  props: {
    name: {
      required: true,
      type: String
    },
    delay: {
      required: false,
      type: Number,
      default: 300
    },
    width: {
      type: Number,
      default: 0
    }
  },
  computed: {
    style() {
      return this.width ? `width: ${this.width}px` : false
    }
  }
}
</script>
<style lang="scss">
$z-index-modal: theme('zIndex.modal');

.zoom-enter-active,
.zoom-leave-active {
  transition: all 1s ease;
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.zoom-enter,
.zoom-leave-to {
  transform: scale3d(1.025, 1.025, 1.025);
  transform-origin: center center;
  opacity: 0;
  transition: all 1s ease;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: $z-index-modal;
  text-align: inherit;
  background: rgba(255, 255, 255, 0.95);

  .modal-wrapper {
    display: table;
    height: 100%;
    width: 100%;
    table-layout: fixed;
    pointer-events: none;
  }

  .modal-center {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-top {
    display: table-caption;
  }

  .modal-footer {
    position: absolute;
    bottom: -80px;
    left: 0;
    right: 0;
  }

  .modal-container {
    position: relative;
    max-width: 100%;
    height: 90vh;
    overflow: auto;

    @screen md {
      height: 61vh;
    }

    &-wrapper {
      @apply px-4;
      z-index: calc(#{$z-index-modal} + 1);
      pointer-events: auto;
      width: auto;
      margin: 0 auto;

      @screen lg {
        width: 660px;
      }

      &:before {
        @screen lg {
          top: -31px;
          right: -13px;
        }
      }

      &:after {
        @screen lg {
          left: -13px;
          bottom: -31px;
        }
      }

      &:before,
      &:after {
        @screen lg {
          @apply absolute border-t;
          content: '';
          width: 85px;
          height: 0;
          transform-origin: center center;
          transform: rotate(-45deg);
        }
      }
    }

    @media (max-width: 992px) {
      min-width: 100%;
      margin: 0;
    }
  }

  .modal-top {
    z-index: calc(#{$z-index-modal} + 1);
  }

  .modal-header {
    >* {
      margin: 0;
    }
  }

  .modal-close {
    font-size: 24px;

    @screen lg {
      font-size: 36px;
      top: 65px;
    }
  }
}

</style>
